import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import basicAuthenticationConfig from "../../utils/basicAuthenticationConfig";
import InputGroup from "../elements/inputGroup/InputGroup";
import LoginGoogleForm from "../login/LoginGoogleForm";
import LoginTwoFaForm from "../login/LoginTwoFaForm";
import { closableNotification } from "../elements/notification/ClosableNotification";
import { AppContext } from "../../App";
import { useHistory } from "react-router-dom";
import { authenticationRequest } from "../../utils/authenticationRequest";
import { regex, responseStatus } from "../../utils/consts";
import { parseAxiosError } from "../../utils/response";
import LoadButton from "../elements/spinner/ButtonSpinner";
import LoginTelegramForm from "../login/LoginTelegramForm";
import LoginNicknameForm from "../login/LoginNicknameForm";
import AlertMessage from "../elements/alert/Alert";
import http from "../../http";

import {
  StyledAuthForm,
  StyledAuthWithSocialWrapper,
  StyledOrAuthBlock,
  StyledOrAuthLine,
  StyledOrAuthText
} from "../login/styledLogin";
import { StyledButton } from "../styles/styledButton";
import ReCaptcha from "../elements/reCaptcha/ReCaptcha";
import { StyledModalLoader } from "../elements/payment/styledPaymentInvoice";
import loadingGif from "../../assets/images/loading.gif";
import {GoogleOAuthProvider} from "@react-oauth/google";

const RegistrationContainer = ({ setVisible }) => {
  const { t } = useTranslation("header");
  const { t: tE } = useTranslation("errors");

  const [existUserNickname, setExistUserNickname] = useState(false);

  const history = useHistory();

  const [errors, setErrors] = useState(null);
  const [existUser, setExistUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientDetails, setClientDetails] = useState({ email: "", password: "", retypedPassword: "" });

  const [authData, setAuthData] = useState({
    headers: null,
    body: null
  });

  const { setAuthenticated, authenticated } = useContext(AppContext);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    authenticationRequest(authData, setAuthenticated, history, setExistUser, setLoading, setVisible, setExistUserNickname, tE);
  }, [authData]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const emailRegex = new RegExp(regex.EMAIL);

    if (!emailRegex.test(clientDetails.email)) {
      setErrors((prevState) => ({
        ...prevState,
        email: tE("invalidEmail")
      }));
      return;
    }

    setLoading(true);

    const captchaToken = recaptchaRef.current.getValue();

    createUser(captchaToken);
  };

  const login = (captchaToken) => {
    setAuthData({
      ...authData,
      body: {
        captchaToken
      },
      headers: basicAuthenticationConfig(clientDetails?.email, clientDetails?.password)
    });
  };

  const createUser = (captchaToken) => {

    http.post(`/api/users`, { ...clientDetails, captchaToken }).then(response => {
      if (response.status === responseStatus.HTTP_CREATED) {
        login(captchaToken);
        closableNotification(t("successfullyRegistered"), "success");
      }
    }).catch(error => {
      recaptchaRef.current.reset();
      const newErrors = parseAxiosError(error.response);
      for (const key of Object.keys(newErrors)) {
        newErrors[key] = tE(newErrors[key]);
      }
      setErrors(newErrors);
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {

        setErrors((prevState) => ({
          ...prevState,
          [Object.keys(error.response.data)]: Object.values(error.response.data)
        }));
        closableNotification(error.response.data.error, "error");
        return null;
      }
    }).finally(() => setLoading(false));
  };

  const onChangeInput = (event) => {
    const { name, value } = event.target;
    if (loading) return;

    setClientDetails((prevState) => ({ ...prevState, [name]: value.trim() }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: null
    }));
  };

  useEffect(() => {
    if (authenticated && existUserNickname) {
      setVisible(false);
    }
  }, [authenticated, existUserNickname, setVisible]);

  const renderTwoFaOrNicknameForm = () => {
    if (loading) {
      return <StyledModalLoader minHeight={"136px"}>
        <img src={loadingGif} alt={"loading..."} />
      </StyledModalLoader>;
    }

    if (!existUserNickname) {
      return <LoginNicknameForm
        loading={loading}
        authData={authData}
        setAuthData={setAuthData}
      />
    }

    return <LoginTwoFaForm
      loading={loading}
      authData={authData}
      setAuthData={setAuthData}
    />
  };


  return (
    !existUser ?
      <div>
        <StyledAuthForm>
          <form onSubmit={handleSubmit}>
            <InputGroup
              id="email"
              type="email"
              label={t("enterEmail")}
              name="email"
              autoComplete="off"
              data-regex={regex.EMAIL}
              required
              error={errors?.["email"]}
              onChange={onChangeInput}
              debounceTimeout={500}
              value={clientDetails.email}
            />
            <InputGroup
              id="password"
              label={t("enterPassword")}
              type="password"
              name="password"
              value={clientDetails.password}
              onChange={onChangeInput}
              error={errors?.password}
              required
            />
            <InputGroup
              id="retypePassword"
              label={t("repeatPassword")}
              type="password"
              name="retypedPassword"
              value={clientDetails.retypedPassword}
              onChange={onChangeInput}
              error={errors?.retypedPassword}
              required
            />
            <ReCaptcha recaptchaRef={recaptchaRef} />
            <div className="auth-action">
              {!loading ?
                <StyledButton
                  color="neutral"
                  type="submit"
                >
                  {t("registration")}
                </StyledButton> :
                <LoadButton
                  color="neutral"
                  text={t("registration")}
                />}
            </div>
          </form>
        </StyledAuthForm>
        <StyledOrAuthBlock>
          <StyledOrAuthLine/>
          <StyledOrAuthText>{t("orUseSocial")}</StyledOrAuthText>
          <StyledOrAuthLine/>
        </StyledOrAuthBlock>
        <StyledAuthWithSocialWrapper>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <LoginGoogleForm
            authData={authData}
            setAuthData={setAuthData}
          />
          </GoogleOAuthProvider>
          <LoginTelegramForm
            authData={authData}
            setAuthData={setAuthData}
          />
        </StyledAuthWithSocialWrapper>
      </div> :
      renderTwoFaOrNicknameForm()
  );
};

export default RegistrationContainer;