import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import Spinner from "../../../elements/spinner/Spinner";
import {useBetween} from "use-between";
import {MainShareableStatistics} from "../../../elements/leftSidebar/Statistics/MainShareableStatistics";

import {StyledAccountActivityItemLg, StyledAccountActivityItemSm} from "./styledClientDetails";

import clock from "../../../../assets/images/account/clock_icon.svg";
import money from "../../../../assets/images/account/wagered_icon.svg";
import btc from "../../../../assets/images/account/earn_icon.svg";
import kubik from "../../../../assets/images/account/bets_icon.svg";
import like from "../../../../assets/images/account/like_icon.svg";
import dislike from "../../../../assets/images/account/dislike_icon.svg";
import clover from "../../../../assets/images/account/clover_icon.svg";
import chat from "../../../../assets/images/account/chat_messages_icon.svg";
import ChangeProfitPrivacyModal from "../ChangeProfitPrivacyModal";
import {AppContext} from "../../../../App";

const ClientDetailsStatisticsContainer = ({nickname, setNotification, notification}) => {

    const [userStatistics, setUserStatistics] = useState({
        days: 0,
        sumOfBet: 0.0,
        profit: 0,
        countOfBet: 0,
        countOfWins: 0,
        countOfLose: 0,
        percentOfLuck: 0,
        countOfMessages: 0
    });
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const {currentStatistics} = useBetween(MainShareableStatistics);
    const {authenticated, user} = useContext(AppContext);

    const {t} = useTranslation("siteOptions");

    const requestCount = useRef(0);
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (requestCount.current >= 3) {
            if (!timeoutRef.current) {
                timeoutRef.current = setTimeout(() => {
                    requestCount.current = 0;
                    timeoutRef.current = null;
                }, 30000);
            }
            return;
        }

        requestCount.current++;
        setLoading(true);
        const abortController = new AbortController();
        axios.get(
            "/api/user-statistics/" + nickname,
            {...userAuthenticationConfig(), signal: abortController.signal}
        ).then(response => {
            if (response.status === 200) {
                setUserStatistics(response?.data);
            }
        }).catch(error => {
            setNotification({...notification, visible: true, type: "error", message: error.response?.data?.message});
        }).finally(setLoading(false));
        return () => {
            abortController.abort();
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    }, [currentStatistics, nickname, notification, setNotification]);

    if (loading) {
        return <Spinner display="block" size="35px"/>
    }

    return (
        userStatistics ?
            <div className="account-wrapper__stats">
                <StyledAccountActivityItemLg>
                    <div className="activity-img">
                        <img src={clock} alt="" width={30}/>
                    </div>
                    <b>{new Intl.NumberFormat("en-US").format(userStatistics.days)} {t("days")}</b>
                    <span>
           {t("daysWith")}
        </span>
                </StyledAccountActivityItemLg>
                <StyledAccountActivityItemLg>
                    <div className="activity-img">
                        <img src={money} alt="" width={30}/>
                    </div>
                    <b>{new Intl.NumberFormat("en-US", {minimumFractionDigits: 2}).format(parseFloat(userStatistics.sumOfBet).toFixed(2))} USDT</b>
                    <span>
           {t("sumOfBets")}
        </span>
                </StyledAccountActivityItemLg>
                <StyledAccountActivityItemLg
                    color={userStatistics.profit > 0 ? "wins" : userStatistics.profit < 0 ? "lose" : ""}
                >
                    <div className="activity-img">
                        <img src={btc} alt="" width={30}/>
                    </div>
                    <b>{userStatistics?.profit !== "" ? `${new Intl.NumberFormat("en-US", {minimumFractionDigits: 2}).format(parseFloat(userStatistics.profit).toFixed(2))} USDT` : t("private")}</b>
                    <span style={{cursor: user.nickname === nickname ? "pointer" : "default"}} className={"profit-title"} onClick={() => setIsModalOpen(true)}>
           {t("profit")}
        </span>
                </StyledAccountActivityItemLg>
                <StyledAccountActivityItemSm>
                    <div className="activity-img">
                        <img src={kubik} alt="" width={30}/>
                    </div>
                    <b>{new Intl.NumberFormat("en-US").format(userStatistics.countOfBet)}</b>
                    <span>
           {t("bets")}
        </span>
                </StyledAccountActivityItemSm>
                <StyledAccountActivityItemSm color="wins">
                    <div className="activity-img">
                        <img src={like} alt="" width={30}/>
                    </div>
                    <b>{new Intl.NumberFormat("en-US").format(userStatistics.countOfWins)}</b>
                    <span>
           {t("winsUpper")}
        </span>
                </StyledAccountActivityItemSm>
                <StyledAccountActivityItemSm color="lose">
                    <div className="activity-img">
                        <img src={dislike} alt="" width={30}/>
                    </div>
                    <b>{new Intl.NumberFormat("en-US").format(userStatistics.countOfLose)}</b>
                    <span>
           {t("loses")}
        </span>
                </StyledAccountActivityItemSm>
                <StyledAccountActivityItemSm>
                    <div className="activity-img">
                        <img src={clover} alt="" width={30}/>
                    </div>
                    <b>{new Intl.NumberFormat("en-US", {minimumFractionDigits: 2}).format(parseFloat(userStatistics.percentOfLuck).toFixed(2))} %</b>
                    <span>
           {t("luck")}
        </span>
                </StyledAccountActivityItemSm>
                <StyledAccountActivityItemSm>
                    <div className="activity-img">
                        <img src={chat} alt="" width={30}/>
                    </div>
                    <b>{new Intl.NumberFormat("en-US").format(userStatistics.countOfMessages)}</b>
                    <span>
           {t("messages")}
        </span>
                </StyledAccountActivityItemSm>
                {authenticated && user.nickname === nickname ? <ChangeProfitPrivacyModal visible={isModalOpen} setVisible={setIsModalOpen}
                                          isHiddenProfit={userStatistics?.hidden_profit}
                                          setUserStatistics={setUserStatistics}/> : null}
            </div> :
            null
    );
};

export default ClientDetailsStatisticsContainer;