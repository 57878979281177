import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {useBetween} from "use-between";
import {useTranslation} from 'react-i18next';
import {NavLink, useLocation} from "react-router-dom";
import Logo from "../logo/Logo";
import Navigation from "../navigation/Navigation";
import MobileNavigation from "../mobileNavigation/MobileNavigation";
import LeftSidebar from "../leftSidebar/LeftSidebar";
import AccountSettingsWrapper from "../../account/client/AccountSettingsWrapper";
import BurgerStates from "../mobileNavigation/BurgerStates";
import {StyledHeaderContainer, StyledHeaderWrapper, StyledMobileNav, StyledWrapper} from "./styledHeader";
import ListLanguage from "../listLanguage/ListLanguage";
import PaymentMethodContainer from "../../games/PaymentMethodContainer";
import {GAMES} from "../../games/Constants";
import {AppContext, MercureUrl} from "../../../App";
import {generatePath} from "../../../utils/getLanguage";
import LeftSidebarChat from "../leftSidebar/Chat/LeftSidebarChat";
import {useLayout} from "../../../utils/useLayout";
import {StyledMenuHeader, StyledMenuHeaderItem} from "../../games/styledPaymentMethod";
import {BalanceNav} from "../mobileNavigation/BalanceNav";
import MobileNavigationMenu from "../leftSidebar/MobileNavigationMenu";
import {generateJWSToken} from "../../../utils/mercureAuth";
import Cookies from "js-cookie";
import {CurrentHonestyControl} from "../leftSidebar/HonestyControl/CurrentHonestyControl";

const getGameNameFromPathName = (pathname) => {
    if (pathname === '/') {
        return GAMES.DICE;
    }
    if (pathname === '/balls') {
        return GAMES.BALLS;
    }
    return GAMES.ROULETTE;
}

const Header = ({page}) => {
    const path = useLocation();
    const {
        submitData,
        setSubmitData,
        responseData,
    } = useBetween(page);
    const {
        isOpenBurger,
        isOpenCurrency,
        isOpenChat,
        setIsOpenChat,
        handleClearAll,
        isOpenProfile,
        setIsOpenProfile
    } = useBetween(BurgerStates);
    const {
        setCurrentHonestyControl,
        setLastHonestyControl
    } = useBetween(CurrentHonestyControl);

    const {user, authenticated} = useContext(AppContext);

    const [menu, setMenu] = useState("");

    const headerRef = useRef(null);

    const {t} = useTranslation('siteOptions');
    const {isMobile} = useLayout();

    const PaymentMethodContainerRender = PaymentMethodContainer;

    useEffect(() => {
        if (isOpenBurger || isOpenCurrency || isOpenChat) {
            headerRef.current.parentElement.parentElement.style = 'overflow-y: hidden';
        } else {
            headerRef.current.parentElement.parentElement.style = 'overflow-y: auto';
        }
    }, [isOpenBurger, isOpenCurrency, isOpenChat]);

    useEffect(() => {
        if (!isMobile) document.body.style.overflowY = "";
    }, [isMobile])

    useEffect(() => {
        const topic = "updateHonestyControl";
        const token = generateJWSToken(topic);
        MercureUrl.searchParams.delete("topic");

        MercureUrl.searchParams.append("topic", topic);

        Cookies.set("mercureAuthorization", token, { path: "" });

        const es = new EventSource(MercureUrl, { withCredentials: true });

        es.addEventListener("message", (event) => {
            let dataMercure = JSON.parse(event.data ?? null);

            if (user && dataMercure.userId && dataMercure.userId === user.userId) {
                setCurrentHonestyControl((prevState) => ({
                    ...prevState,
                    serverSeedHash: dataMercure.nextServerSeedHash,
                    [dataMercure.game.toLowerCase()]: {
                        serverSeedHash: dataMercure.nextServerSeedHash,
                    }
                }));
                setLastHonestyControl((prevState) => ({
                    ...prevState,
                    serverSeed: dataMercure.serverSeed,
                }));
            }
        });

        return () => {
            es.close();
        };
    }, [user, setLastHonestyControl]);

    const leftSidebar = useMemo(() => isMobile ? <LeftSidebar isNavigation mobileNavBtnIsHidden /> : null, [isMobile]);
    const mobileNavMenu = useMemo(() => isMobile ? <MobileNavigationMenu /> : null, [isMobile]);

    const paymentMethodContainerRender = useMemo(
        () => isMobile ? (
            <PaymentMethodContainerRender
                responseData={responseData}
                paymentMethod={submitData?.paymentMethod}
                setSubmitData={setSubmitData}
                isNavigation={true}
                game={getGameNameFromPathName(path.pathname)}
            />
        ) : null,
        [isMobile, responseData, submitData?.paymentMethod, setSubmitData, path.pathname],
    );

    const mobileNavigation = useMemo(() => isMobile ? <MobileNavigation paymentMethod={submitData?.paymentMethod} /> : null, [isMobile, submitData?.paymentMethod]);
    const navigation = useMemo(() => !isMobile ? <Navigation/> : null, [isMobile]);
    const leftSidebarChat = useMemo(() => isMobile
        ? (
            <LeftSidebarChat
                setVisible={setIsOpenChat}
                setMenu={setMenu}
                visible={isOpenChat}
                isNavigation={true}
            />
        )
        : null, [isMobile, setIsOpenChat, setMenu, isOpenChat]);


    return (
        <>
            {leftSidebar}
            <StyledHeaderWrapper ref={headerRef}>
                <StyledHeaderContainer wrapper="content">
                    <Logo/>
                    {navigation}
                    {mobileNavigation}
                </StyledHeaderContainer>
                <StyledMobileNav isOpen={isOpenBurger}>
                    {mobileNavMenu}
                    {authenticated ?
                        (
                            <StyledWrapper className={'account-nav'}>
                                <h3>{t('hello')}, {user.nickname}</h3>
                                <AccountSettingsWrapper
                                    isNavigation={
                                        <NavLink
                                            to={generatePath(`/account/${user.nickname}`)}
                                            onClick={handleClearAll}
                                            className="link-profile">{t('myProfile')}
                                        </NavLink>}/>
                            </StyledWrapper>
                        ) : ""
                    }
                    <StyledWrapper>
                        <h3>{t('menu')}</h3>
                        <Navigation/>
                    </StyledWrapper>
                    <StyledWrapper>
                        <h3>{t('language')}</h3>
                        <ListLanguage/>
                    </StyledWrapper>
                </StyledMobileNav>
                {leftSidebarChat}
                <StyledMobileNav isOpenCurrency={isOpenCurrency} fullscreen={true}>
                    {paymentMethodContainerRender}
                </StyledMobileNav>
                {authenticated && <>
                    <BalanceNav/>
                    <StyledMobileNav isOpen={isOpenProfile} fullscreen={true}>
                        <StyledMenuHeader>
                            <StyledMenuHeaderItem
                                className={'nav-title'}>{t('hello')}, {user.nickname}</StyledMenuHeaderItem>
                            <StyledMenuHeaderItem>
                                <section
                                    className="close"
                                    onClick={() => setIsOpenProfile(false)}
                                ></section>
                            </StyledMenuHeaderItem>
                        </StyledMenuHeader>
                        <AccountSettingsWrapper
                            isNavigation={
                                <NavLink
                                    to={generatePath(`/account/${user.nickname}`)}
                                    onClick={handleClearAll}
                                    className="link-profile">{t('myProfile')}
                                </NavLink>}
                        />
                    </StyledMobileNav>
                </>
                }
            </StyledHeaderWrapper>
        </>
    );
};

export default Header;